import $ from "jquery";
import 'slick-carousel';
import scrollSpy from 'simple-scrollspy';

let timeout1 = {value: ""};
let timeout2 = {value: ""};
const $scollLogo = $('.scrollLogo');
const $bigLogo = $('#bigLogo');
const $whiteSpace = $('.whiteSpace');
const $etapeSlider = $('.etape-slider');
const $rowFile = $('.row-file');
const $fileLabel = $('#file-label');
const $fileLabel2 = $('#customFile');

function removeSlideClass(slide) {
    slide.classList.remove("previousSlideRight");
    slide.classList.remove("previousSlideLeft");
    slide.classList.remove("nextSlideRight");
    slide.classList.remove("nextSlideLeft");
}

function getCurrentSlide() {
    let dots = document.getElementById("carousel2").querySelector(".container-dots").getElementsByClassName("dot");
    let previousSlide;

    for(let i = 0; i < dots.length; i++){
        if (dots[i].classList.contains("current")){
            previousSlide = i + 1;
        }
    }

    return previousSlide;
}

function changeSlide(parent, slideIndex, timeout) {
    let previousSlide;
    let slides = document.getElementById(parent).getElementsByClassName("slide");

    if (slideIndex == 0){
        slideIndex++;
    } else {
        slideIndex++;

        if (slideIndex > slides.length) {slideIndex = 1}

        if(slideIndex == 1){
            previousSlide = slides.length;
        } else {
            previousSlide = slideIndex - 1;
        }
        removeSlideClass(slides[slideIndex-1]);
        removeSlideClass(slides[previousSlide-1])

        slides[slideIndex-1].classList.add("nextSlideRight");
        slides[previousSlide-1].classList.add("previousSlideLeft");

        if( document.getElementById(parent).querySelector(".container-dots") ){
            let dots = document.getElementById(parent).querySelector(".container-dots").getElementsByClassName("dot");
            dots[slideIndex-1].classList.toggle("current");
            dots[previousSlide-1].classList.toggle("current");
        }
    }

    timeout.value = setTimeout(function () {
        changeSlide(parent, slideIndex, timeout);
    }, 4000);
}

function currentSlide(index) {
    let slides = document.getElementById("carousel2").getElementsByClassName("slide");
    let dots = document.getElementById("carousel2").querySelector(".container-dots").getElementsByClassName("dot");
    let previousSlide = getCurrentSlide();

    if(index != previousSlide) {
        clearTimeout(timeout2.value);
        removeSlideClass(slides[index-1]);
        removeSlideClass(slides[previousSlide-1])

        if(index > previousSlide) {
            slides[index-1].classList.add("nextSlideRight");
            slides[previousSlide-1].classList.add("previousSlideLeft");
        } else if (index < previousSlide) {
            slides[index-1].classList.add("nextSlideLeft");
            slides[previousSlide-1].classList.add("previousSlideRight");
        }

        dots[index-1].classList.toggle("current");
        dots[previousSlide-1].classList.toggle("current");

        timeout2.value = setTimeout(function () {
            changeSlide("carousel2", index, timeout2);
        }, 4000);
    }
}

function plusSlides(number) {
    let previousSlide = getCurrentSlide();
    let slideIndex;

    if(previousSlide + number > 10) {
        slideIndex = 1
    } else if (previousSlide + number < 1) {
        slideIndex = 10;
    } else {
        slideIndex = previousSlide + number;
    }

    currentSlide(slideIndex);
}

let carousel1 = document.getElementById("carousel1");
if (carousel1) {
    changeSlide("carousel1", 0, timeout1);
}

function toggleLight() {

    let lights = document.querySelector(".lights").querySelectorAll("div");

    lights.forEach((element, index) => {
        let descriptionLeft = document.getElementById("descriptionGauche"+(index+1));
        let descriptionRight = document.getElementById("descriptionDroite"+(index+1));

        element.classList.toggle("light-on");
        descriptionLeft.toggleAttribute("hidden");
        descriptionRight.toggleAttribute("hidden");

        if (element.classList.contains("light-on")) {
            element.querySelector("img").src = "/dist/images/interet/button-light-on-"+(index+1)+".svg"
        } else {
            element.querySelector("img").src = "/dist/images/interet/button-light-off-"+(index+1)+".svg"
        }
    });
}

function toggleProcess(id, swipe = true) {
    let etapes = document.querySelector(".chronologie").getElementsByClassName("etape");
    let slides = document.getElementById("processus").getElementsByClassName("etape-slide");
    let i;
    for(i = 0; i < etapes.length; i++) {
        let image = etapes[i].querySelector("img");

        if(id == etapes[i].id) {
            etapes[i].classList.remove("past");
            etapes[i].classList.add("active");
            image.src = "/dist/images/processus/"+id+"-active.svg";
            //slides[i].hidden = false;
            break;
        } else {
            etapes[i].classList.remove("active");
            etapes[i].classList.add("past");
            image.src = "/dist/images/processus/"+etapes[i].id+"-past.svg";
            //slides[i].hidden = true;
        }

    }

    for(i = i+1; i < etapes.length; i++){
        let image = etapes[i].querySelector("img");
        etapes[i].classList.remove("active");
        etapes[i].classList.remove("past");
        image.src = "/dist/images/processus/"+etapes[i].id+"-inactive.svg";
        //slides[i].hidden = true;
    }

    if(swipe) {
        if(id === 'candidature') {
            $etapeSlider.slick('slickGoTo', 0, true);
        } else if (id === 'accompagnement'){
            $etapeSlider.slick('slickGoTo', 1, true);
        } else if (id === 'selection'){
            $etapeSlider.slick('slickGoTo', 2, true);
        } else if (id === 'contractualisation'){
            $etapeSlider.slick('slickGoTo', 3, true);
        }
    }
}

function resizeLogo() {
    let containerLogo = document.querySelector(".scrollLogo");
    let scrollLogo = containerLogo.querySelector("img");
    let fixedLogo = document.getElementById("bigLogo");
    let fillerSpace = document.querySelector(".whiteSpace");
    let opacity = (1 - document.documentElement.scrollTop*2 / 1000);

    if (document.documentElement.scrollTop >= 4 * 100){ // 4 * number of scroll
        containerLogo.style.display= "none";
        fillerSpace.style.height = (fillerSpace.offsetHeight - document.documentElement.scrollTop)+"px";
        fixedLogo.style.visibility = "visible";
        window.onscroll = function () {reduceFillerSpace()};
        window.scrollTo(0, 0);
    } else {
        $whiteSpace.addClass('scroll');
        containerLogo.style.backgroundColor = "rgb(255, 255, 255, "+opacity+")";
        scrollLogo.style.height = (50 - document.documentElement.scrollTop*7/100)+"%"; // 50 because scrollLogo.height is 50%
    }
}

function reduceFillerSpace () {
    let fillerSpace = document.querySelector(".whiteSpace");

    if (fillerSpace.offsetHeight > 0){
        let newHeight = fillerSpace.offsetHeight - document.documentElement.scrollTop;

        if(newHeight < 0) {
            newHeight = 0;
        }

        fillerSpace.style.height = newHeight+"px";
        window.scrollTo(0, 0);
    } else {
        window.onscroll = "";
    }
}

function fadeLogo(){
    $bigLogo.hide();
    $bigLogo.css('visibility', 'visible');
    $scollLogo.fadeOut(1000);
    return $.when($scollLogo).done().promise();
}
function scrollToTarget(target, speed){
    $('html, body').animate({
        scrollTop:$(target).offset().top - 40
    }, speed, "swing", function() {
        $whiteSpace.css('height', 0);
    });
}
function checkForm(){
    let check = true;
    $('[required]').each(function() {
        if ($(this).is(':invalid') || !$(this).val() || $(this).hasClass('error')) check = false;
    })
    if (check) {
        $('.candidature .submit').prop("disabled", false);
    } else {
        $('.candidature .submit').prop("disabled", "disabled");
    }
}

function drawMap(partners) {

    let themes = [];
    let cluster1 = 0;
    partners.forEach((partner, index) => {

        let map =  document.querySelector('.partners--map--container');
        let div = document.createElement('button');
        div.classList.add('partners--pin');

        if(partner.cluster) {
            div.classList.add('pin-cluster');
            div.classList.add('pin-' + partner.cluster);

            if(partner.cluster === "cluster-1") {
                cluster1++;
            }
        }

        // Set Data
        div.dataset.name = partner.name;
        div.dataset.city = partner.city;
        div.dataset.cp = partner.cp;
        div.dataset.enjeux = partner.enjeux;
        div.dataset.partner = partner.partner;
        div.dataset.partners = partner.partners;
        div.dataset.principes = partner.principes;
        div.dataset.status = partner.status;
        div.dataset.theme = partner.theme;
        div.dataset.url = partner.url;

        // Set images
        let images = partner.images.split(";");
        images.forEach((image) => {
            div.dataset.images = image;
        });

        // Set Skill
        div.dataset.mat_techno = partner.mat_techno;
        div.dataset.mat_indus = partner.mat_indus;
        div.dataset.mat_usage = partner.mat_usage;

        // Set Pos
        div.style.top = partner.pos_y + "px";
        div.style.left = partner.pos_x + "px";

        map.appendChild(div)

        // Get themes
        if(partner.theme.length > 0) {
            // Check if theme contains ","
            if(partner.theme.includes(",")) {
                let temp = partner.theme.split(",");
                temp.forEach((themeTemp, index) => {
                    if(!themes.includes(themeTemp)) {
                        themes.push(themeTemp);
                    }
                });
            } else {
                if(!themes.includes(partner.theme)) {
                    themes.push(partner.theme);
                }
            }
        }
    });

    // Draw for mobile list
    let partnersList = document.querySelector('.partners--map--list');
    let partnersDoublon = [];
    partners.forEach((partner, index) => {
        if(!partnersDoublon.includes(partner.name)) {
            partnersDoublon.push(partner.name);
            let div = document.createElement('button');
            div.classList.add('partner');
            div.innerText = partner.name;

            // Set Data
            div.dataset.name = partner.name;
            div.dataset.city = partner.city;
            div.dataset.cp = partner.cp;
            div.dataset.enjeux = partner.enjeux;
            div.dataset.partner = partner.partner;
            div.dataset.partners = partner.partners;
            div.dataset.principes = partner.principes;
            div.dataset.status = partner.status;
            div.dataset.theme = partner.theme;
            div.dataset.url = partner.url;

            // Set images
            let images = partner.images.split(";");
            images.forEach((image) => {
                div.dataset.images = image;
            });

            // Set Skill
            div.dataset.mat_techno = partner.mat_techno;
            div.dataset.mat_indus = partner.mat_indus;
            div.dataset.mat_usage = partner.mat_usage;

            partnersList.appendChild(div);
        }
    });

    $('.cluster-1 .value').text(cluster1);
    if(cluster1 === 0) {
        $('.cluster-1').addClass('hidden');
    } else {
        $('.cluster-1').removeClass('hidden');
    }

    // Draw themes
    themes.forEach((theme, index) => {
        let li = document.createElement('li');
        li.dataset.value = theme;
        li.innerText = theme;
        document.querySelector('.partners--filter--dropdown ul').appendChild(li);
    });
}

function updateMap(theme) {
    let pins = document.querySelectorAll('.partners--pin');
    let cluster1 = 0;
    pins.forEach((pin, index) => {
        if(theme === "Thématique" || pin.dataset.theme.includes(theme)) {
            pin.classList.remove('hidden');
            if(pin.classList.contains('pin-cluster-1')) {
                cluster1++;
            }
        } else {
            pin.classList.add('hidden');
        }
    });

    $('.cluster-1 .value').text(cluster1);

    // Optimize code bellow
    if(cluster1 === 0) {
        $('.cluster-1').addClass('hidden');
    } else {
        $('.cluster-1').removeClass('hidden');
    }

    // Update list
    pins = document.querySelectorAll('.partners--map--list .partner');
    pins.forEach((pin, index) => {
        if(theme === "Thématique" || pin.dataset.theme.includes(theme)) {
            pin.classList.remove('hidden');
        } else {
            pin.classList.add('hidden');
        }
    });
}

function drawPartnerModal(el) {
    let modal = document.querySelector('.partners--popup');

    let name = modal.querySelector('.partners--popup--title');
    name.innerText = el.data('name');

    let status = modal.querySelector('.partners--popup--status');
    status.innerText = el.data('status');

    let enjeux = modal.querySelector('.partners--popup--enjeux');
    enjeux.innerHTML = '';
    let split = el.data('enjeux').split(";");
    split.forEach((item) => {
        let li = document.createElement('li');
        li.innerText = item;
        enjeux.appendChild(li);
    });

    let principes = modal.querySelector('.partners--popup--principes');
    principes.innerHTML = '';
    split = el.data('principes').split(";");
    split.forEach((item) => {
        let li = document.createElement('li');
        li.innerText = item;
        principes.appendChild(li);
    });

    let partners = modal.querySelector('.partners--popup--partners');
    partners.innerHTML = '';
    split = el.data('partners').split(";");
    let urls = el.data('url').split(";");

    split.forEach((item, index) => {
        let a = document.createElement('a');
        a.href = urls[index];
        a.target = "_blank";

        let img = document.createElement('img');
        let filename = item.replace(/\s+/g, '-').toUpperCase() + '.png';
        img.src = '/dist/images/partners/' + filename;
        img.alt = item;

        a.appendChild(img);
        partners.appendChild(a);
    });

    let images = modal.querySelector('.partners--popup--img');
    images.src = '/dist/images/projects/' + el.data('images');
    images.alt = el.data('name');
    console.log(el.data('images'))
    if (el.data('images') == "PERIPHAS.png") {
        console.log("test")
        console.log(images)
        $(images).css({"background-color" : "white"});
    }

    let skills = {
        'mat_techno': el.data('mat_techno'),
        'mat_indus': el.data('mat_indus'),
        'mat_usage': el.data('mat_usage')
    }

    drawCanvas(skills);
}

function drawCanvas(skills) {
    const canvas = document.querySelector('.partners--popup--graph');
    const levels = 6; // Nombre de niveaux
    const ctx = canvas.getContext("2d");

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    function drawRadar() {
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Rayon maximal pour couvrir le canvas
        const maxRadius = Math.min(centerX, centerY);

        for (let i = 0; i < levels; i++) {
            const radius = (maxRadius / levels) * (i + 1);
            drawTriangle(centerX, centerY, radius);
        }
    }

    function drawTriangle(x, y, radius) {
        ctx.beginPath();
        for (let i = 0; i < 3; i++) {
            // Décalage de -Math.PI/2 pour orienter le sommet vers le haut
            const angle = (i * 2 * Math.PI / 3) - (Math.PI / 2);
            const triangleX = x + radius * Math.cos(angle);
            const triangleY = y + radius * Math.sin(angle);

            if (i === 0) {
                ctx.moveTo(triangleX, triangleY);
            } else {
                ctx.lineTo(triangleX, triangleY);
            }
        }

        ctx.closePath();
        ctx.strokeStyle = "rgba(255,255,255,0.2)";
        ctx.stroke();
    }

    function drawSkill(values) {
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        const triangleRadius = (canvas.height / 2) * (Math.max(...values) / levels);

        ctx.beginPath();

        for (let i = 0; i < 3; i++) {
            const valueIndex = i % values.length;
            const value = values[valueIndex];
            const radius = (canvas.height / 2) * (value / levels);

            const angle = (i * 2 * Math.PI / 3) - (Math.PI / 2);
            const triangleX = centerX + triangleRadius * Math.cos(angle);
            const triangleY = centerY + triangleRadius * Math.sin(angle);

            const pointX = centerX + radius * Math.cos(angle);
            const pointY = centerY + radius * Math.sin(angle);

            if (i === 0) {
                ctx.moveTo(pointX, pointY);
            } else {
                ctx.lineTo(pointX, pointY);
            }
        }

        ctx.closePath();
        ctx.strokeStyle = "rgba(219, 104, 52, 1)";
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        ctx.stroke();
    }

    drawRadar();
    drawSkill([skills['mat_techno'], skills['mat_indus'], skills['mat_usage']]);
}

window.onload = function() {

    if ($(window).width() < 1024) {
        let hash = window.location.hash;
        if(hash){
            $scollLogo.hide();
            $('.layout').removeClass('is-active');
            $(hash).addClass('is-active');
        }
    }

    // Smooth Scroll
    $('.anchor-nav[href^="#"]').click(function(){

        // Load slider
        $etapeSlider.slick('refresh');

        // Apply class
        let target = $(this).attr("href");

        // Desktop
        if ($(window).width() >= 1024) {
            // Check if logo is visible
            if($scollLogo.is(':visible')) {
                fadeLogo().done(function(){
                    $bigLogo.fadeIn(1000);
                    if(target !== '#accueil') {
                        scrollToTarget(target, 1500);
                    } else {
                        $whiteSpace.animate({height:0},1000);
                    }
                });
            } else {
                scrollToTarget(target, 1000);
            }
        } else {
            $scollLogo.hide();
            $('.layout').removeClass('is-active');
            $(target).addClass('is-active');
            $('.anchor').toggleClass('is-active');
            $('footer').toggleClass('is-active');
            window.location.hash = target;
        }
        return false;
    });

    // Carrousel 2
    $('#carousel2').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        speed: 300,
        autoplay: true
    });

    // Carrousel 3
    $('#carousel3').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        speed: 300,
        autoplaySpeed: 5000,
        autoplay: true
    });

    // PROCESSUS CARROUSEL
    $etapeSlider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        speed: 300,
        autoplay: false
    });

    $etapeSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if(nextSlide === 0) {
            toggleProcess('candidature', false);
        } else if(nextSlide === 1) {
            toggleProcess('accompagnement', false);
        } else if(nextSlide === 2) {
            toggleProcess('selection', false);
        } else if(nextSlide === 3) {
            toggleProcess('contractualisation', false);
        }
    });

    //Toggle light content
    $('.perimetre-toggle').on( 'click', function() {
        toggleLight();

        if ($(window).width() < 1024) {
            if($(this).parent().hasClass('hors-perimetre')){
                $('.schema-mobile .content-1').hide();
                $('.schema-mobile .content-2').show();
            } else {
                $('.schema-mobile .content-1').show();
                $('.schema-mobile .content-2').hide();
            }
        }
    });

    //Toggle light content
    $('#processus .etape').on( 'click', function(e) {
        e.preventDefault();
        toggleProcess($(this).data('process'));
    });

    // Burger menu
    $('#menu').on( 'click', function() {
        $('.anchor').toggleClass('is-active');
        $('footer').toggleClass('is-active');
    });

    // update Atout content on mobile
    if ($(window).width() < 1024) {
        $('.atout.layout').clone().appendTo('#processus');
        $('#processus .layout').removeClass('layout');
    }

    $('.candidature form input').keypress(function() {
        checkForm();
    });
    $('.candidature form select, .candidature form input').change(function() {
        checkForm();
    });

    $('.candidature form textarea').keyup(function() {
        let counter = $('.candidature form .counter');
        let textLength = $(this).val().length;
        counter.find('span').text(textLength);
        textLength >= 3000 ? counter.addClass('error') : counter.removeClass('error');
    });

    // Add files to contact form
    $(document).on("change", ".hidden-file", function(){
        // Show files
        let errorFile = $('#error-file');
        let weight = drawFileList();
        errorFile.text('');

        if(weight > 5242880) {
            $(this).val('');
            drawFileList();
            errorFile.text('La ou les pièces jointes ne doivent pas excéder 5 Mo au total.');
        } else if ($fileLabel2.hasClass('disabledFile')) {
            errorFile.text('Le nombre maximum de pièces jointes est atteint.');
        }
    });

    // Delete file event on contact form
    $(document).on("click", ".disabledFile", function(e){
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    // Delete file event on contact form
    $(document).on("click", ".delete-file", function(){
        let target = $(this).data('id');
        $('#file-' + target).val('');
        drawFileList();
    });

    // Scrollspy for nav
    let offetSpy = $(window).height() / 1.5;
    if($('.scrollspy').length) {
        scrollSpy('.anchor', {
            sectionClass: '.scrollspy',
            menuActiveTarget: '.anchor-nav',
            offset: offetSpy
        });
    }

    // Email Validation
    $("#email").focusout(function() {
        if($(this).val().length > 0){
            if(checkEmail($(this).val())) {
                $(this).removeClass('error');
                $('#error-email').text("");
            } else {
                $(this).addClass('error');
                $('#error-email').text("Le format de l'email est invalide.");
            }
        }
    });

    $("#tel").focusout(function(){
        let numTel = $(this).val();
        let rgxPhone = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/
        if (numTel.match(rgxPhone)){
            $(this).removeClass('error');
            $('#error-tel').text("");
        } else {
            $(this).addClass('error');
            $('#error-tel').text("Le format du numéro de téléphone est invalide.");
        }
    });

    if (location.hash === '#error-captcha') {
        $('#error-captcha').text('Captcha invalide.');
        scrollToTarget($('#error-captcha'), 800);
    }

    // Dropdown
    const $dropdownPartner = $('.partners--filter--dropdown');
    const $dropdownPartnerTitle = $('.partners--filter--dropdown--title');
    $dropdownPartnerTitle.on('click', function() {
        $dropdownPartner.toggleClass('active');
    });

    $dropdownPartner.on("click", 'ul li', function(event) {
        $dropdownPartner.removeClass('active');
        $dropdownPartnerTitle.find('span').text($(this).data('value'));

        updateMap($(this).data('value'));
    });

    //Toggle partners on mobile
    $('.partners--filter--toggle').on( 'click', function() {
        $(this).toggleClass('active');
        $('.partners--map--list').toggleClass('active');
        $('.partners--map').toggleClass('active');
    });

    // Partners
    let partnersJson = false;
    fetch('partners.json')
        .then(res => res.json())
        .then(data => {
            drawMap(data);
        })
        .catch(err => { throw err });

    $dropdownPartner.on("click", 'ul li', function(event) {
        $dropdownPartner.removeClass('active');
        $dropdownPartnerTitle.find('span').text($(this).data('value'));
        updateMap($(this).data('value'));
    });

    const $pinPopup = $('.partners--pin--popup');
    const $mapContainer = $('.partners--map--container');

    $mapContainer.on("mouseenter", '.cluster', function(event) {
        let el = $(this);
        let target = el.data('target');

        // Cut and copy target inside this
        $('.' + target).each(function( index ) {
            let item = $(this).detach();
            el.append(item);
            item.addClass('cluster-on');
        });
    }).on("mouseleave", '.cluster', function(event) {
        let target = $(this).data('target');
        $('.' + target).removeClass('cluster-on');
    });

    $mapContainer.on("mouseenter", '.partners--pin', function(event) {
        $('.partners--pin').removeClass('active');
        $pinPopup.removeClass('active');

        let $target = $(this);
        $target.addClass('active');

        // Cut and copy $pinPopup
        $pinPopup.detach();
        $target.append($pinPopup);

        // Set name
        $pinPopup.find('.title').text($target.data('name'));

        // Set themes
        let themes = $target.data('theme').split(",");
        $pinPopup.find('.themes').empty();
        themes.forEach((theme) => {
            $pinPopup.find('.themes').append('<span class="theme">' + theme + '</span>');
        });

        // Set partners
        let partners = $target.data('partners').split(";");
        $pinPopup.find('.partners').empty();
        partners.forEach((partner) => {
            let filename = partner.replace(/\s+/g, '-').toUpperCase() + '.png';
            $pinPopup.find('.partners').append('<img src="/dist/images/partners/' + filename + '" alt="' + partner + '" />');
        });
        $pinPopup.addClass('active');

        // Draw modal
        drawPartnerModal($target);

    }).on("mouseleave", '.partners--pin', function(event) {
        $('.partners--pin').removeClass('active');
        $pinPopup.removeClass('active');
    });

    $('.partners--map--list').on("click", '.partner', function(event) {
        let $target = $(this);
        // Draw modal
        drawPartnerModal($target);

        $('.partners--popup').addClass(('active'));
        $('.partners--popup--bg').addClass(('active'));
        $('body').addClass('modal-open');
    });

    $(document).on("click", function(event) {
        if (!$(event.target).closest(".partners--pin").length) {
            $('.partners--pin').removeClass('active');
            $pinPopup.removeClass('active');
        }
    });

    const $partnerModal = $('.partners--popup');
    const $partnerModalBg = $('.partners--popup--bg');
    $('.partners--pin--popup').on("click", function(event) {
        $partnerModal.addClass(('active'));
        $partnerModalBg.addClass(('active'));
        $('body').addClass('modal-open');
    });
    $('.partners--popup--close').on("click", function(event) {
        $partnerModal.removeClass(('active'));
        $partnerModalBg.removeClass(('active'));
        $('body').removeClass('modal-open');
    });
}

window.onunload = function () { // called on refresh
    let containerLogo = document.querySelector(".scrollLogo");
    let fixedLogo = document.getElementById("bigLogo");

    try {
        containerLogo.style.display= "block";
        fixedLogo.style.visibility = "hidden";
        window.scrollTo(0, 0);
    } finally {
        window.onscroll = function () {resizeLogo()};
    }
}

window.onscroll = function () {
    if($scollLogo.is(':visible')) {
        resizeLogo();
    }
};

function drawFileList() {

    $rowFile.empty();
    let initialize = false;
    let weight = 0;

    $('.hidden-file').each(function(i) {
        let target = $(this).attr('id').substring(5, 6);

        if($(this).val().length > 0){
            let file = $(this)[0].files[0];
            weight += $(this)[0].files[0].size;
            $rowFile.append('<div><span class="delete-file" data-id="' + target + '">×</span><span>' + file.name + '</span></div>');
        } else if (!initialize){
            $fileLabel.attr('for', 'file-' + target );
            $fileLabel2.attr('for', 'file-' + target );
            initialize = true;
            $fileLabel2.removeClass('disabledFile');
        }

    });

    if(!initialize){
        $fileLabel2.addClass('disabledFile');
    }

    return weight;
}

function checkEmail(email) {
    let emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test(email);
}
